// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-calculator-tsx": () => import("./../../../src/pages/portfolio/calculator.tsx" /* webpackChunkName: "component---src-pages-portfolio-calculator-tsx" */),
  "component---src-pages-portfolio-drum-machine-tsx": () => import("./../../../src/pages/portfolio/drum-machine.tsx" /* webpackChunkName: "component---src-pages-portfolio-drum-machine-tsx" */),
  "component---src-pages-portfolio-game-of-life-tsx": () => import("./../../../src/pages/portfolio/game-of-life.tsx" /* webpackChunkName: "component---src-pages-portfolio-game-of-life-tsx" */),
  "component---src-pages-portfolio-markdown-previewer-tsx": () => import("./../../../src/pages/portfolio/markdown-previewer.tsx" /* webpackChunkName: "component---src-pages-portfolio-markdown-previewer-tsx" */),
  "component---src-pages-portfolio-pomodoro-clock-tsx": () => import("./../../../src/pages/portfolio/pomodoro-clock.tsx" /* webpackChunkName: "component---src-pages-portfolio-pomodoro-clock-tsx" */),
  "component---src-pages-portfolio-quickfrac-tsx": () => import("./../../../src/pages/portfolio/quickfrac.tsx" /* webpackChunkName: "component---src-pages-portfolio-quickfrac-tsx" */),
  "component---src-pages-portfolio-quote-box-tsx": () => import("./../../../src/pages/portfolio/quote-box.tsx" /* webpackChunkName: "component---src-pages-portfolio-quote-box-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/BlogList.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/Tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

